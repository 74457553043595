import axios from 'axios'
const fetchUserManagement = ($auth, store, flag) => {
  if (flag) {
    const qontakLaunchpadURL = process.env.QONTAK_LAUNCHPAD_URL
    const headers = {
      authorization: process.env.QONTAK_LAUNCHPAD_BASIC_AUTH,
    }
    axios
      .get(
        `${qontakLaunchpadURL}/private/users/${$auth.user.sso_id}/permissions`,
        { app_name: 'qontak_chat' },
        headers
      )
      .then((response) => {
        store.commit('organization/UPDATE_NEW_USER_MANAGEMENT', response.data)
      })
      .catch(() => {})
  }
}

export { fetchUserManagement }
