import Vue from 'vue'
import Pixel, {
  MpText,
  MpButton,
  MpButtonIcon,
  MpFlex,
  MpBox,
  MpIcon,
  MpModal,
  MpModalOverlay,
  MpModalContent,
  MpModalHeader,
  MpModalFooter,
  MpModalBody,
  MpModalCloseButton,
  MpSpinner,
  MpInput,
  MpInputGroup,
  MpInputLeftAddon,
  MpInputRightAddon,
  MpInputTag,
  MpAutocomplete,
  MpSelect,
  MpTooltip,
  MpGrid,
  MpGridItem,
  MpFormControl,
  MpCheckbox,
  MpCheckboxGroup,
  MpDatePicker,
  MpSimpleGrid,
  MpUpload,
  MpTag,
  MpBanner,
  MpBannerTitle,
  MpBannerDescription,
  MpBannerIcon,
  MpBannerCloseButton,
  MpToast,
  MpPopover,
  MpPopoverTrigger,
  MpPopoverContent,
  MpPopoverList,
  MpPopoverListItem,
  MpPopoverPanel,
  MpToggle,
  MpPseudoBox,
  MpBadge,
  MpProgress,
  MpTextarea,
  MpAvatar,
  MpStack,
  MpAvatarGroup,
  MpDivider,
  MpTabs,
  MpTabList,
  MpTab,
  MpTabPanels,
  MpTabPanel,
  MpRadioGroup,
  MpRadio,
  MpTable,
  MpTableHead,
  MpTableBody,
  MpTableRow,
  MpTableCell,
  MpChart,
  MpFormLabel,
  MpFormErrorMessage,
  MpFormHelpText,
  MpImage,
  MpDrawer,
  MpDrawerOverlay,
  MpDrawerContent,
  MpDrawerHeader,
  MpDrawerCloseButton,
  MpDrawerBody,
  MpDrawerFooter,
  MpTableContainer,
  MpCollapse,
  MpAccordion,
  MpAccordionItem,
  MpAccordionHeader,
  MpAccordionPanel,
  MpAccordionIcon,
  MpLoader,
  MpDropzone,
  MpDropzonePreview,
  MpRating,
  MpAireneButton,
  MpSkeleton,
  MpAireneChatInput,
  MpAireneChatBubble,
  MpButtonGroup,
} from '@mekari/pixel'

Vue.component('MpText', MpText)
Vue.component('MpButton', MpButton)
Vue.component('MpButtonIcon', MpButtonIcon)
Vue.component('MpFlex', MpFlex)
Vue.component('MpBox', MpBox)
Vue.component('MpIcon', MpIcon)
Vue.component('MpModal', MpModal)
Vue.component('MpModalOverlay', MpModalOverlay)
Vue.component('MpModalContent', MpModalContent)
Vue.component('MpModalHeader', MpModalHeader)
Vue.component('MpModalFooter', MpModalFooter)
Vue.component('MpModalBody', MpModalBody)
Vue.component('MpModalCloseButton', MpModalCloseButton)
Vue.component('MpSpinner', MpSpinner)
Vue.component('MpInput', MpInput)
Vue.component('MpInputGroup', MpInputGroup)
Vue.component('MpInputLeftAddon', MpInputLeftAddon)
Vue.component('MpInputRightAddon', MpInputRightAddon)
Vue.component('MpInputTag', MpInputTag)
Vue.component('MpAutocomplete', MpAutocomplete)
Vue.component('MpSelect', MpSelect)
Vue.component('MpTooltip', MpTooltip)
Vue.component('MpGrid', MpGrid)
Vue.component('MpGridItem', MpGridItem)
Vue.component('MpFormControl', MpFormControl)
Vue.component('MpCheckbox', MpCheckbox)
Vue.component('MpCheckboxGroup', MpCheckboxGroup)
Vue.component('MpDatePicker', MpDatePicker)
Vue.component('MpSimpleGrid', MpSimpleGrid)
Vue.component('MpUpload', MpUpload)
Vue.component('MpTag', MpTag)
Vue.component('MpBanner', MpBanner)
Vue.component('MpBannerTitle', MpBannerTitle)
Vue.component('MpBannerDescription', MpBannerDescription)
Vue.component('MpBannerIcon', MpBannerIcon)
Vue.component('MpBannerCloseButton', MpBannerCloseButton)
Vue.component('MpToast', MpToast)
Vue.component('MpPopover', MpPopover)
Vue.component('MpPopoverTrigger', MpPopoverTrigger)
Vue.component('MpPopoverContent', MpPopoverContent)
Vue.component('MpPopoverList', MpPopoverList)
Vue.component('MpPopoverListItem', MpPopoverListItem)
Vue.component('MpPopoverPanel', MpPopoverPanel)
Vue.component('MpToggle', MpToggle)
Vue.component('MpPseudoBox', MpPseudoBox)
Vue.component('MpBadge', MpBadge)
Vue.component('MpProgress', MpProgress)
Vue.component('MpTextarea', MpTextarea)
Vue.component('MpAvatar', MpAvatar)
Vue.component('MpStack', MpStack)
Vue.component('MpAvatarGroup', MpAvatarGroup)
Vue.component('MpDivider', MpDivider)
Vue.component('MpTabs', MpTabs)
Vue.component('MpTabList', MpTabList)
Vue.component('MpTab', MpTab)
Vue.component('MpTabPanels', MpTabPanels)
Vue.component('MpTabPanel', MpTabPanel)
Vue.component('MpRadioGroup', MpRadioGroup)
Vue.component('MpRadio', MpRadio)
Vue.component('MpTable', MpTable)
Vue.component('MpTableHead', MpTableHead)
Vue.component('MpTableBody', MpTableBody)
Vue.component('MpTableRow', MpTableRow)
Vue.component('MpTableCell', MpTableCell)
Vue.component('MpTableContainer', MpTableContainer)
Vue.component('MpChart', MpChart)
Vue.component('MpFormLabel', MpFormLabel)
Vue.component('MpFormErrorMessage', MpFormErrorMessage)
Vue.component('MpFormHelpText', MpFormHelpText)
Vue.component('MpImage', MpImage)
Vue.component('MpDrawer', MpDrawer)
Vue.component('MpDrawerOverlay', MpDrawerOverlay)
Vue.component('MpDrawerContent', MpDrawerContent)
Vue.component('MpDrawerHeader', MpDrawerHeader)
Vue.component('MpDrawerCloseButton', MpDrawerCloseButton)
Vue.component('MpDrawerBody', MpDrawerBody)
Vue.component('MpDrawerFooter', MpDrawerFooter)
Vue.component('MpCollapse', MpCollapse)
Vue.component('MpAccordion', MpAccordion)
Vue.component('MpAccordionItem', MpAccordionItem)
Vue.component('MpAccordionHeader', MpAccordionHeader)
Vue.component('MpAccordionPanel', MpAccordionPanel)
Vue.component('MpAccordionIcon', MpAccordionIcon)
Vue.component('MpLoader', MpLoader)
Vue.component('MpDropzone', MpDropzone)
Vue.component('MpDropzonePreview', MpDropzonePreview)
Vue.component('MpRating', MpRating)
Vue.component('MpAireneButton', MpAireneButton)
Vue.component('MpSkeleton', MpSkeleton)
Vue.component('MpAireneChatInput', MpAireneChatInput)
Vue.component('MpAireneChatBubble', MpAireneChatBubble)
Vue.component('MpButtonGroup', MpButtonGroup)

// For responsive, we use bootstrap breakpoints
const customBreakpoints = {
  xs: '0px',
  sm: '576px',
  md: '768px',
  lg: '992px',
  xl: '1200px',
}

Vue.use(Pixel, {
  extendTheme: {
    breakpoints: customBreakpoints,
  },
})
